 
<template>
  <div class="container">
    <div class="mt-1 mb-2">
      <div class="text-center">
        <h4>Report Download Panel</h4>
      </div>
    </div>
    <!-- button on both side -->
    <b-overlay :show="is_loding" v-if="is_sup" variant="transparent" opacity="1.0" blur="2px" spinner-variant="primary"
      spinner-type="grow" rounded="sm">
      <a @click="pregen()" style="color:green;">
        <b>Pre Generate</b>
      </a>

      <div class="row">
        <div class="col-12 col-lg-6 mb-1">
          <input class="form-control" placeholder="From DTM ID" type="text" v-model="from_id" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <input class="form-control" placeholder="To DTM ID" type="text" v-model="to_id" />
        </div>
      </div>
      <b-input-group>
        <b-input-group-prepend>
          <b-form-select v-model="year" :options="yearoptions" />
        </b-input-group-prepend>
        <b-form-select v-model="selected" :options="options" />
        <b-input-group-append>
          <b-button variant="outline-primary" @click="getdata()">
            GET !
          </b-button>
          <b-button variant="outline-primary" @click="getfilterdata()">
            GET filter!
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="text-center mt-5 mb-2" v-if="is_data">
        <download-csv class="btn btn-success mb-2" :data="json_data"
          :name="listName[selected - 1] + '-' + nowdate + '.csv'">
          Download CSV ({{ json_data.length || 0 }})
          <feather-icon icon="DownloadIcon" />
        </download-csv>
      </div>
    </b-overlay>
    <div v-else>
      <div class="text-center">
        <h4 style="color: red;">Only for Super Admin</h4>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
  BOverlay,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      from_id: "",
      to_id: "",
      selected: null,
      year: null,
      options: [
        { value: null, text: "Please select an option" },
        { value: "1", text: "Yearly Collection List" },
        { value: "2", text: "Yearly Donation List" },
        { value: "3", text: "Yearly Due/Adv/Total List" },
        { value: "4", text: "Yearly Sundy Fund List" },
        { value: "5", text: "Yearly Reg Fee List", disabled: false },
        { value: "6", text: "Yearly Cash Transaction", disabled: false },
        { value: "7", text: "Yearly Transaction", disabled: false },
        { value: "8", text: "Yearly Payment Unverified", disabled: false },
        { value: "9", text: "Yearly Debit List", disabled: false },
        { value: "10", text: "Yearly Food Sponsor List", disabled: false },
        { value: "11", text: "Yearly Food Distribution", disabled: false },
      ],
      listName: [
        "Monthly_Collection",
        "Monthly_Donation",
        "Monthly_Due/Adv/Total",
        "Sundy_Fund",
        "Reg_Fee",
        "Cash_Tran",
        "All_tran",
        "Payment_Unverified",
        "Debit",
        "Food_sponsor",
        "Food_distribution",
      ],
      yearoptions: [{ value: null, text: "Please select Year" }],
      is_data: false,
      is_loding: false,
      is_sup: false,
      json_data: [],
    };
  },
  computed: {
    nowdate() {
      let date = new Date();
      return date;
    },
  },
  methods: {
    getYears() {
      let start = 2020;
      const date = new Date();
      let end = date.getFullYear();
      console.log(end);
      for (start; start <= end; start++) {
        this.yearoptions.push({ value: start, text: start });
      }
    },
    getdata() {
      if (this.year && this.selected) {
        this.is_loding = true;
        this.$http
          .get(`/dtm/report/${this.year || 2020}/${this.selected || 0}/`)
          .then((response) => {
            this.json_data = response.data;
            this.is_data = true;
            this.is_loding = false;
          })
          .catch((error) => {
            this.is_loding = false;
            console.log(error)
          });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Select Year and Options", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    getfilterdata() {
      if (this.year && this.selected) {
        this.is_loding = true;
        this.$http
          .get(`/dtm/dtmids/${this.year || 2020}/${this.selected || 0}/${this.from_id || 0}/${this.to_id || 0}`)
          .then((response) => {
            this.json_data = response.data;
            this.is_data = true;
            this.is_loding = false;
          })
          .catch((error) => {
            this.is_loding = false;
            console.log(error)
          });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Select Year and Options", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    pregen() {
      if (this.year && this.selected) {
        this.is_loding = true;
        this.$http
          .post(`/dtm/report/${this.year || 2020}/${this.selected || 0}/`, {})
          .then((response) => {
            // this.json_data = response.data;
            // this.is_data = true;
            let variant = "success";
            this.$bvToast.toast("Report Generate Requested Successfully. Pls wait few second", {
              title: `Success`,
              variant,
              solid: true,
            });
            this.is_loding = false;
          })
          .catch((error) => {
            console.log(error)
            this.is_loding = false;
          });
      } else {
        let variant = "danger";
        this.$bvToast.toast("Pls Select Year and Options", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
  },
  created() {
    this.getYears();
    var userdata = JSON.parse(localStorage.getItem("userData"));
    this.is_sup = userdata.is_sup;
  },
};
</script>
 